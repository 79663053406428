import styles from '@/pages/configurations/countries-configurations/CountriesConfigurations.module.scss';
import UserPermissionsGuard from '@/components/user-permissions/UserPermissionsGuard';
import Button from '@/components/common/button/Button';
import { Permissions } from '@/types';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import PaymentMethodTopupModal from '@/components/configurations/payment-method-topup/PaymentMethodTopupModal';
import PaymentMethodGroupTopupModal from '@/components/configurations/payment-method-group-topup/PaymentMethodGroupTopupModal';
import PaymentMethodsGroupsTable from '@/components/configurations/payment-method-group-topup/PaymentMethodsGroupsTable';
import PaymentMethodsTable from '@/components/configurations/payment-method-group-topup/PaymentMethodsTable';

const PaymentMethodsTopUp = (): JSX.Element => {
  const { t } = useTranslation('configurations');
  const [open, setOpen] = useState(false);
  const [openGroup, setOpenGroup] = useState(false);
  const [activeGroupId, setActiveGroupId] = useState('');

  return (
    <>
      {activeGroupId === '' && (
        <>
          <div className={styles.titleTopup}>
            <UserPermissionsGuard permission={Permissions.countryConfigEdit}>
              <div className={styles.buttons}>
                <Button
                  textTransform='uppercase'
                  onClick={() => setOpenGroup(true)}
                >
                  + {t('payment-method-topup.button-new-method-group')}
                </Button>
              </div>
            </UserPermissionsGuard>
          </div>
          <div className={styles.emptyCard}>
            <UserPermissionsGuard permission={Permissions.countryConfigRead}>
              <PaymentMethodsGroupsTable setActiveGroupId={setActiveGroupId} />
            </UserPermissionsGuard>
          </div>
        </>
      )}
      {activeGroupId !== '' && (
        <UserPermissionsGuard permission={Permissions.countryConfigRead}>
          <PaymentMethodsTable
            groupId={activeGroupId}
            setActiveGroupId={setActiveGroupId}
            setOpen={setOpen}
          />
        </UserPermissionsGuard>
      )}
      {open && <PaymentMethodTopupModal onClose={() => setOpen(false)} />}
      {openGroup && <PaymentMethodGroupTopupModal onClose={() => setOpenGroup(false)} />}
    </>
  );
};

export default PaymentMethodsTopUp;
