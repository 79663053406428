import { type PayloadAction, createSlice } from '@reduxjs/toolkit';
import { savePaymentMethodTopup, getPaymentMethodTopupByGroup } from './actions';
import type PaymentMethod from '@/types/PaymentMethod';

export interface PaymentMethodTopupState {
  paymentMethods: PaymentMethod[];
  successCreateMethod: boolean;
  loadingCreateMethod: boolean;
  loadingGetMethods: boolean;
}

const initialState: PaymentMethodTopupState = {
  paymentMethods: [],
  loadingCreateMethod: false,
  successCreateMethod: false,
  loadingGetMethods: false,
};

export const paymentMethodTopupSlice = createSlice({
  name: 'paymentMethodTopup',
  initialState,
  reducers: {
    reset() {
      return { ...initialState };
    },
    resetCreateMethod(state) {
      return {
        paymentMethods: state.paymentMethods,
        loadingGetMethods: state.loadingGetMethods,
        successCreateMethod: false,
        loadingCreateMethod: false,
      };
    },
  },
  extraReducers: builder => {
    builder.addCase(savePaymentMethodTopup.pending, state => {
      state.loadingCreateMethod = true;
    });
    builder.addCase(savePaymentMethodTopup.fulfilled, state => {
      state.loadingCreateMethod = false;
      state.successCreateMethod = true;
    });
    builder.addCase(savePaymentMethodTopup.rejected, state => {
      state.loadingCreateMethod = false;
      state.successCreateMethod = false;
    });
    builder.addCase(getPaymentMethodTopupByGroup.pending, state => {
      state.loadingGetMethods = true;
    });
    builder.addCase(getPaymentMethodTopupByGroup.fulfilled, (state, action: PayloadAction<PaymentMethod[]>) => {
      state.paymentMethods = [...action.payload];
      state.loadingGetMethods = false;
    });
    builder.addCase(getPaymentMethodTopupByGroup.rejected, state => {
      state.loadingGetMethods = false;
    });
  },
});

export const { reset, resetCreateMethod } = paymentMethodTopupSlice.actions;
