import { useTranslation } from 'react-i18next';

import styles from '@/pages/configurations/countries-configurations/CountriesConfigurations.module.scss';
import Table from '@/components/common/tables/Table';
import EditIcon from '@/components/icons/EditIcon';
import RelateIcon from '@/components/icons/RelateIcon';
import type PaymentMethod from '@/types/PaymentMethod';

export default function PaymentMethodsTableRow({
  paymentMethod,
}: Readonly<{ paymentMethod: PaymentMethod }>): React.JSX.Element {
  const { t } = useTranslation('configurations');

  return (
    <Table.Row>
      <Table.Cell>{paymentMethod.name}</Table.Cell>
      <Table.Cell>{paymentMethod.id}</Table.Cell>
      <Table.Cell>{paymentMethod.order}</Table.Cell>
      <Table.Cell>{paymentMethod.reason}</Table.Cell>
      <Table.Cell>{paymentMethod.channelId}</Table.Cell>
      <Table.Cell>{paymentMethod.isEnabled ? t('payment-method-topup.yes') : t('payment-method-topup.no')}</Table.Cell>
      <Table.Cell>
        <div className={styles.actions}>
          <EditIcon />
          <RelateIcon />
        </div>
      </Table.Cell>
    </Table.Row>
  );
}
