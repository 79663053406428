import { useMemo, useEffect } from 'react';
import { Outlet, useParams, useNavigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch, type RootState } from '@/lib/redux';
import { useTranslation } from 'react-i18next';
import NavigationTabs from '@/components/common/navigation-tabs/NavigationTabs';
import BackButton from '@/components/common/button/implementations/BackButton';
import { type TabInfo } from '@/utils/interfaces';
import styles from './CountryConfigurationsLayout.module.scss';
import Typography from '@/components/common/typography/Typography';
import { resetCountry, turnGetCountryLoadingOff } from '@/lib/redux/slices/country/slice';
import { getCountry } from '@/lib/redux/slices/country/actions';
import { getComplianceDocs } from '@/lib/redux/slices/compliance-docs/actions';
import { resetComplianceDocs, resetComplianceDocsLoading } from '@/lib/redux/slices/compliance-docs/slice';
import Spinner from '@/components/common/spinners/Spinner';

export default function CountryConfigurationsLayout(): React.JSX.Element {
  const { t } = useTranslation('configurations');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { country, getCountryLoading } = useAppSelector((state: RootState) => state.country);

  const { isLoadingComplianceDocs } = useAppSelector((state: RootState) => state.complianceDocs);

  const { countryCode } = useParams<{ countryCode: string }>();

  const tabDisabled = country.countryCode === '';

  const tabs: TabInfo[] = useMemo(() => {
    return [
      { linkText: t('country-configurations-layout.tab-country-information'), linkPath: 'country-information' },
      { linkText: t('country-configurations-layout.tab-channels'), linkPath: 'channels', disabled: tabDisabled },
      {
        linkText: t('country-configurations-layout.tab-payment-methods-top-up'),
        linkPath: 'payment-methods-top-up',
        disabled: tabDisabled,
      },
      {
        linkText: t('country-configurations-layout.tab-payment-methods-withdrawals'),
        linkPath: 'payment-methods-withdrawals',
        disabled: tabDisabled,
      },
      { linkText: t('country-configurations-layout.tab-limits'), linkPath: 'limits', disabled: tabDisabled },
      { linkText: t('country-configurations-layout.tab-other'), linkPath: 'other', disabled: tabDisabled },
    ];
  }, [t, tabDisabled]);

  useEffect(() => {
    if (countryCode !== undefined && countryCode !== 'new') {
      const code = countryCode.toUpperCase();
      void dispatch(getCountry({ countryCode: code }));
      void dispatch(getComplianceDocs({ countryCode: code }));
    } else {
      dispatch(turnGetCountryLoadingOff());
      dispatch(resetComplianceDocsLoading());
    }
    return () => {
      dispatch(resetCountry());
      dispatch(resetComplianceDocs());
    };
  }, [countryCode, dispatch]);

  const handleBack = (): void => navigate('/configurations/countries');

  return (
    <>
      <div className={styles.backButton}>
        <BackButton onClick={handleBack}>{t('country-configurations-layout.button-back')}</BackButton>
      </div>
      <div className={styles.card}>
        <div className={styles.header}>
          <Typography variant='h1'>
            {country.countryName === '' ? t('new-country.new-country') : country.countryName}
          </Typography>
          <NavigationTabs tabs={tabs} />
        </div>
        {(getCountryLoading || isLoadingComplianceDocs) && <Spinner />}
        {!getCountryLoading && !isLoadingComplianceDocs && <Outlet />}
      </div>
    </>
  );
}
