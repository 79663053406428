import { createSlice } from '@reduxjs/toolkit';
import { savePaymentMethodGroupTopup, getPaymentMethodGroupTopup } from './actions';
import type PaymentMethodGroup from '@/types/PaymentMethodGroup';

export interface PaymentMethodGroupTopupState {
  paymentMethodGroup: PaymentMethodGroup[];
  success: boolean;
  loading: boolean;
}

const initialState: PaymentMethodGroupTopupState = {
  paymentMethodGroup: [],
  loading: false,
  success: false,
};

export const paymentMethodGroupTopupSlice = createSlice({
  name: 'paymentMethodGroupTopup',
  initialState,
  reducers: {
    reset(state) {
      return { paymentMethodGroup: state.paymentMethodGroup, loading: false, success: false };
    },
  },
  extraReducers: builder => {
    builder.addCase(savePaymentMethodGroupTopup.pending, state => {
      state.loading = true;
    });
    builder.addCase(savePaymentMethodGroupTopup.fulfilled, state => {
      state.loading = false;
      state.success = true;
    });
    builder.addCase(savePaymentMethodGroupTopup.rejected, state => {
      state.loading = false;
      state.success = false;
    });
    builder.addCase(getPaymentMethodGroupTopup.pending, state => {
      state.loading = true;
    });
    builder.addCase(getPaymentMethodGroupTopup.fulfilled, (state, action) => {
      state.paymentMethodGroup = [...action.payload];
      state.loading = false;
    });
    builder.addCase(getPaymentMethodGroupTopup.rejected, state => {
      state.loading = false;
      state.success = false;
    });
  },
});

export const { reset } = paymentMethodGroupTopupSlice.actions;
