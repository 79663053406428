import authAxios from '@/auth/axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import type PaymentMethod from '@/types/PaymentMethod';
import { errorToast, successToast } from '@/utils/toastMessage';

export const savePaymentMethodTopup = createAsyncThunk(
  'paymentMethodTopup.savePaymentMethodTopup',
  async (data: PaymentMethod, { rejectWithValue }) => {
    try {
      data.allowInCheckout = true;
      const response = await authAxios.post('/topup/payment-method', data);

      successToast('toast-messages.payment-method-created');

      return response.data as PaymentMethod;
    } catch (err: any) {
      errorToast();
      return rejectWithValue(err);
    }
  },
);

export const getPaymentMethodTopupByGroup = createAsyncThunk(
  'paymentMethodTopup.getPaymentMethodTopupByGroup',
  async (data: { countryCode: string; groupId: string | null }, { rejectWithValue }) => {
    try {
      const response = await authAxios.get(
        `/topup/payment-method/paginated?GroupId=${data.groupId}&Country=${data.countryCode}`,
      );

      return response.data.data.data as PaymentMethod[];
    } catch (err: any) {
      errorToast();
      return rejectWithValue(err);
    }
  },
);
