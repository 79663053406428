import { useTranslation } from 'react-i18next';
import { type Dispatch, useEffect } from 'react';
import { useAppDispatch, useAppSelector, type RootState } from '@/lib/redux';

import { getPaymentMethodGroupTopup } from '@/lib/redux/slices/payment-method-groups-topup/actions';
import Spinner from '@/components/common/spinners/Spinner';
import Table from '@/components/common/tables/Table';
import PaymentMethodsGroupsTableRow from './PaymentMethodsGroupsTableRow';

export default function PaymentMethodsGroupsTable({
  setActiveGroupId,
}: Readonly<{
  setActiveGroupId: Dispatch<string>;
}>): React.JSX.Element {
  const { paymentMethodGroup, loading, success } = useAppSelector((state: RootState) => state.paymentMethodGroupTopup);
  const { country } = useAppSelector((state: RootState) => state.country);

  const { t } = useTranslation('configurations');

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (country?.countryCode) {
      void dispatch(getPaymentMethodGroupTopup(country.countryCode));
    }
  }, [dispatch, country]);

  useEffect(() => {
    if (success && country?.countryCode) {
      void dispatch(getPaymentMethodGroupTopup(country.countryCode));
    }
  }, [dispatch, country, success]);

  const noGroupPaymentMethods = {
    id: 'defined-as-null',
    icon: '-',
    nameTranslationKey: '',
    name: t('payment-method-topup.no-group-methods'),
    description: '-',
    descriptionTranslationKey: '-',
    order: 0,
    countryCode: '-',
    reason: '-',
    isEnabled: false,
  };

  return (
    <>
      {loading && <Spinner />}
      {!loading && (
        <Table cols={5}>
          <Table.Head>{t('payment-method-topup.name')}</Table.Head>
          <Table.Head>{t('payment-method-topup.id')}</Table.Head>
          <Table.Head>{t('payment-method-topup.order')}</Table.Head>
          <Table.Head>{t('payment-method-topup.reason')}</Table.Head>
          <Table.Head>{t('payment-method-topup.enabled')}</Table.Head>
          {paymentMethodGroup.map(paymentMethodsGroup => {
            return (
              <PaymentMethodsGroupsTableRow
                key={paymentMethodsGroup.id}
                paymentMethodsGroup={paymentMethodsGroup}
                setActiveGroupId={setActiveGroupId}
              />
            );
          })}
          <PaymentMethodsGroupsTableRow
            key={noGroupPaymentMethods.id}
            paymentMethodsGroup={noGroupPaymentMethods}
            setActiveGroupId={setActiveGroupId}
          />
        </Table>
      )}
    </>
  );
}
