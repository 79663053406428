import authAxios from '@/auth/axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { errorToast } from '@/utils/toastMessage';

export const getTrustedDevice = createAsyncThunk(
  'customer.getCustomerTrustedDevice',
  async ({ customerId }: { customerId: string }, { rejectWithValue }) => {
    try {
      const { data } = await authAxios.get('/client-certificates', { headers: { 'x-customer-id': customerId } });
      return data.data;
    } catch (err: any) {
      if (err.response.data.errors[0].errorMessage !== 'err_no_certificates_found') {
        errorToast();
      }
      return rejectWithValue(err);
    }
  },
);
