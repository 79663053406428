import styles from './CountryTranslation.module.scss';
import Typography from '@/components/common/typography/Typography';
import { mediumPurple } from '@/utils/colors';
import { useTranslation } from 'react-i18next';
import InputLabel from '@/components/common/labels/InputLabel';
import Input from '@/components/common/inputs/Input';
import styled from 'styled-components';
import { useCallback, useMemo, useState } from 'react';
import FileUploadInput from '@/components/common/inputs/FileUploadInput';
import Accordion from '@/components/common/accordion/Accordion';
import { AccordionTitle } from '@/components/configurations';
import { objectDeepEquality } from '@/utils/helperFunctions';
import type { Translation } from '@/types/Translation';
import Button from '@/components/common/button/Button';
import { addCountryTranslation } from '@/lib/redux/slices/country-translation/actions';
import { type RootState, useAppDispatch, useAppSelector } from '@/lib/redux';
import Dropdown from '@/components/common/filters/Dropdown';
import { type Option } from '@/utils/interfaces';

const GridItem = styled.div<{ $cell?: [number?, number?] }>`
  grid-column: ${({ $cell = [1, 1] }) => {
    const start = $cell[0];
    const span = $cell[1] ?? 1;
    return `${start} / span ${span}`;
  }};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  @media (max-width: 1090px) {
    grid-column: 1 / span 5;
  }
`;
export default function CountryTranslation(): JSX.Element {
  const { t } = useTranslation('configurations');
  const [active, setActive] = useState<boolean>(false);
  const { translation } = useAppSelector((state: RootState) => state.countryTranslation);
  const [translationParameters, setTranslationParameters] = useState<Translation>({
    versionType: '',
    delimitier: '',
    languageCode: '',
  });

  const dispatch = useAppDispatch();

  const isTranslationChanged = useMemo(
    () => !translation || !objectDeepEquality(translationParameters, translation),
    [translationParameters, translation],
  );

  const hasValidTranslationInputs = useCallback((): boolean => {
    return (
      translationParameters.delimitier.length > 0 &&
      translationParameters.languageCode.length > 0 &&
      translationParameters.file !== undefined
    );
  }, [translationParameters]);

  const handleSubmit = (): void => {
    if (hasValidTranslationInputs()) {
      void dispatch(addCountryTranslation(translationParameters));
    }
  };

  const renderContent = (
    <div className={styles.card}>
      <div className={styles.cardContent}>
        <div className={styles.label}>
          <Typography
            variant='p3'
            color={mediumPurple.cssColor}
          >
            {t('new-country.country-translations-description')}
          </Typography>
        </div>
        <div className={styles.inputCard}>
          <div className={styles.gridCard}>
            <GridItem $cell={[1, 2]}>
              <InputLabel label={'Code'}>
                <Input
                  onChange={(value: string) =>
                    setTranslationParameters({ ...translationParameters, languageCode: value })
                  }
                  value={translationParameters.languageCode}
                />
              </InputLabel>
            </GridItem>
            <GridItem $cell={[3, 2]}>
              <InputLabel label={'Version'}>
                <Dropdown
                  options={[
                    { text: '--', value: '' },
                    { text: 'minor', value: 'minor' },
                    { text: 'major', value: 'major' },
                  ]}
                  onSelect={(value: Option) =>
                    setTranslationParameters({ ...translationParameters, versionType: value.value ?? '' })
                  }
                  selectedOption={{
                    value: translationParameters.versionType,
                    text: translationParameters.versionType === '' ? '--' : translationParameters.versionType,
                  }}
                  setSelectedOption={() => {}}
                />
              </InputLabel>
            </GridItem>
            <GridItem $cell={[5, 2]}>
              <InputLabel label={'Delimiter'}>
                <Input
                  onChange={(value: string) =>
                    setTranslationParameters({ ...translationParameters, delimitier: value })
                  }
                  value={translationParameters.delimitier}
                />
              </InputLabel>
            </GridItem>
            <GridItem $cell={[1, 4]}>
              <InputLabel label={'From file(.csv)'}>
                <FileUploadInput
                  accept={'.csv'}
                  onChange={file => setTranslationParameters({ ...translationParameters, file })}
                />
              </InputLabel>
            </GridItem>
            <GridItem $cell={[5, 2]} />
          </div>
        </div>
      </div>
      <div className={styles.buttons}>
        <Button
          textTransform='uppercase'
          disabled={isTranslationChanged && !hasValidTranslationInputs()}
          onClick={handleSubmit}
        >
          {t('new-country.button-submit-configuration')}
        </Button>
      </div>
    </div>
  );

  return (
    <Accordion
      title={
        <AccordionTitle
          isValid={hasValidTranslationInputs() && !isTranslationChanged}
          title={t('new-country.country-translations-title')}
        />
      }
      content={renderContent}
      isActive={active}
      onClick={() => setActive(!active)}
    />
  );
}
