import React from 'react';
import { useTranslation } from 'react-i18next';
import Table from '@/components/common/tables/Table';
import TickIcon from '@/components/icons/TickIcon';
import { toUtcDateString } from '@/utils/dateTimeUtils';
import WidgetBox from '@/components/customer/widget-box/WidgetBox';
import { useKycApplications } from '@/utils/hooks/useKycApplications';
import { type Customer } from '@/types';
import SomethingWentWrong from '@/components/common/something-went-wrong/SomethingWentWrong';
import styles from './PepSanctionChecks.module.scss';

export interface PepSanctionChecksProps {
  customer: Customer;
}

const PepSanctionChecks: React.FC<PepSanctionChecksProps> = ({ customer }) => {
  const { t } = useTranslation('customerOverview');
  const { kycInfo, isError } = useKycApplications(customer.customerId ?? '');

  if (isError) {
    return <SomethingWentWrong />;
  }

  const timestamp = kycInfo.sanctionsRetrievedDate;
  const isPepChecked = customer.officialPep;
  const isSanctionChecked = customer.isSanctionResult;

  return (
    <WidgetBox title={t('widgets.pep-and-sactions.header-title')}>
      <div className={styles.container}>
        <Table colsTemplate='1fr 3.5rem 1fr'>
          <Table.Head>
            <div />
          </Table.Head>
          <Table.Head>
            <div />
          </Table.Head>
          <Table.Head>{t('widgets.pep-and-sactions.timestamp-of-completion')}</Table.Head>
          <Table.Row>
            <Table.Cell>Is PEP checked?</Table.Cell>
            <Table.Cell>{isPepChecked && <TickIcon />}</Table.Cell>
            <Table.Cell>{isPepChecked && timestamp && toUtcDateString(timestamp)}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Is sanction checked?</Table.Cell>
            <Table.Cell>{isSanctionChecked && <TickIcon />}</Table.Cell>
            <Table.Cell>{isSanctionChecked && timestamp && toUtcDateString(timestamp)}</Table.Cell>
          </Table.Row>
        </Table>
        <div className={styles.riskRatingLabel}>
          {t('widgets.customer-pii.account-data.risk-rating')} : {customer.amlRating ?? 'N/A'}
        </div>
      </div>
    </WidgetBox>
  );
};

export default PepSanctionChecks;
