import authAxios from '@/auth/axios';
import {
  type KycApplicationResponse,
  KycApplicationResponseState,
  type KycApplications,
  type KycIdvDetails,
  VerificationStepStatus,
} from '@/types';
import DebounceCache from '@/utils/cache/DebounceCache';
import { createAsyncThunk } from '@reduxjs/toolkit';

const cache = new DebounceCache<string, KycApplicationResponse | null>();

export const fetchKycApplications = createAsyncThunk(
  'kyc.fetchKyc',
  async ({ customerId }: { customerId: string }, { rejectWithValue }) => {
    try {
      const application = await cache.getResult(customerId, async () => {
        const allAppsResponse = await authAxios.get<{
          data: { list: KycApplicationResponse[] };
        }>('/kyc-applications', {
          headers: { 'x-customer-id': customerId },
        });
        const latestApp = getApplicationObjectFromResponse(allAppsResponse.data.data);
        if (latestApp?.id) {
          const latestAppResponse = await authAxios.get<{ data: KycApplicationResponse }>(
            `/kyc-applications/${latestApp.id}`,
            {
              headers: { 'x-customer-id': customerId },
            },
          );

          if (latestAppResponse.data?.data?.id) {
            return latestAppResponse.data.data;
          }
        }

        return null;
      });

      const customerIdKey = customerId;
      const isApproved = isApplicationApproved(application);
      const idvApprovedDate = hasVerifiedStep(application, VerificationStepStatus.IdvApproved);
      const idvDocApprovedDate = hasVerifiedStep(application, VerificationStepStatus.IdentityDocApproved);
      const edvApprovedDate = hasVerifiedStep(application, VerificationStepStatus.EdvApproved);
      const addressVerifiedDate = hasVerifiedStep(application, VerificationStepStatus.AddressApproved);
      const addressDocVerifiedDate = hasVerifiedStep(application, VerificationStepStatus.AddressDocApproved);
      const sanctionsRetrievedDate = hasVerifiedStep(application, VerificationStepStatus.SanctionsRetrieved);
      const riskApprovedDate = hasVerifiedStep(application, VerificationStepStatus.RiskApproved);
      const idvPiiApprovedDate = hasVerifiedStep(application, VerificationStepStatus.IdvPiiApproved);
      const addressEdvApprovedDate = hasVerifiedStep(application, VerificationStepStatus.AddressEdvApproved);
      const addressDocApprovedDate = hasVerifiedStep(application, VerificationStepStatus.AddressDocApproved);
      const geolocationApprovedDate = hasVerifiedStep(application, VerificationStepStatus.AddressGeolocationApproved);

      const idvDocCheckStepForId = getVerificationStepDetails(application, [VerificationStepStatus.IdvDocCheck]);
      const idvDocCheckStepForStatus = getVerificationStepDetails(application, [
        VerificationStepStatus.IdvRejected,
        VerificationStepStatus.IdvApproved,
      ]);

      const kycApplications: KycApplications = {
        customerId: customerIdKey,
        isApplicationApproved: isApproved,
        idvPiiApprovedDate,
        addressEdvApprovedDate,
        idvApprovedDate,
        addressDocApprovedDate,
        geolocationApprovedDate,
        idvDocApprovedDate,
        edvApprovedDate,
        addressVerifiedDate,
        addressDocVerifiedDate,
        sanctionsRetrievedDate,
        applicationDate: application?.createdOn,
        id: application?.id,
        documents: application?.documents,
        idvDocCheckId: idvDocCheckStepForId,
        idvDocCheckStatuses: idvDocCheckStepForStatus,
        riskApprovedDate,
      };

      return kycApplications;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

function getApplicationObjectFromResponse(
  response?: { list: KycApplicationResponse[] } | null,
): KycApplicationResponse | null {
  if (response?.list && response.list.length > 0) {
    return response.list.reduce((i1, i2) => {
      return new Date(i1.createdOn).getTime() > new Date(i2.createdOn).getTime() ? i1 : i2;
    }, response.list[0]);
  }

  return null;
}

function hasVerifiedStep(
  application: KycApplicationResponse | null,
  stepStatus: VerificationStepStatus,
): string | undefined {
  if (application) {
    const step = application.details.find(curr => curr.statusTo === stepStatus);
    if (step?.timeStamp?.length) {
      return step.timeStamp;
    }
  }
  return undefined;
}

function getVerificationStepDetails(
  application: KycApplicationResponse | null,
  stepStatus: VerificationStepStatus[],
): KycIdvDetails | undefined {
  if (application) {
    const step = application.details.find(curr => stepStatus.includes(curr.statusTo));
    if (step) {
      return step.details;
    }
  }
  return undefined;
}

function isApplicationApproved(application: KycApplicationResponse | null): boolean {
  return application?.state === KycApplicationResponseState.Approved;
}
