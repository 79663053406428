import Modal from '@/components/common/modals/Modal';
import InputLabel from '@/components/common/labels/InputLabel';
import Input from '@/components/common/inputs/Input';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../payment-method-topup/PaymentMethodTopupModal.module.scss';
import Button from '@/components/common/button/Button';
import { type RootState, useAppDispatch, useAppSelector } from '@/lib/redux';
import { parseNumberOrDefault } from '@/utils/helperFunctions';
import Typography from '@/components/common/typography/Typography';
import { reset } from '@/lib/redux/slices/payment-method-groups-topup/slice';

import { Checkbox } from 'k8-web-lib-tmp';
import type PaymentMethodGroup from '@/types/PaymentMethodGroup';
import { savePaymentMethodGroupTopup } from '@/lib/redux/slices/payment-method-groups-topup/actions';

interface Props {
  onClose: () => void;
}

export default function PaymentMethodGroupTopupModal({ onClose }: Readonly<Props>): JSX.Element {
  const { t } = useTranslation('configurations');
  const [paymentMethodGroup, setPaymentMethodGroup] = useState<PaymentMethodGroup>({
    icon: '',
    name: '',
    nameTranslationKey: '',
    countryCode: '',
    isEnabled: false,
    description: '',
    descriptionTranslationKey: '',
    order: 0,
    reason: '',
  });
  const { success, loading } = useAppSelector((state: RootState) => state.paymentMethodGroupTopup);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (success) {
      onClose();
    }
    return () => {
      dispatch(reset());
    };
  }, [success, onClose, dispatch]);

  const onSubmit = (): void => {
    void dispatch(savePaymentMethodGroupTopup(paymentMethodGroup));
  };

  const isValid = useMemo(() => {
    return Object.values(paymentMethodGroup).every(value => {
      if (value === undefined) return false;
      if (typeof value === 'string') return value.length > 0;
      if (typeof value === 'number') return value > 0;
      return true;
    });
  }, [paymentMethodGroup]);

  return (
    <Modal onClose={onClose}>
      <div className={styles.title}>
        <Typography variant='p1SemiBold'>{t('payment-method-topup.title-create-group')}</Typography>
      </div>
      <div className={styles.container}>
        <div className={styles.child}>
          <InputLabel label={t('payment-method-topup.icon')}>
            <Input
              value={paymentMethodGroup.icon}
              onChange={value => setPaymentMethodGroup({ ...paymentMethodGroup, icon: value })}
            />
          </InputLabel>
          <InputLabel label={t('payment-method-topup.name')}>
            <Input
              value={paymentMethodGroup.name}
              onChange={value => setPaymentMethodGroup({ ...paymentMethodGroup, name: value })}
            />
          </InputLabel>
          <InputLabel label={t('payment-method-topup.description')}>
            <Input
              value={paymentMethodGroup.description}
              onChange={value => setPaymentMethodGroup({ ...paymentMethodGroup, description: value })}
            />
          </InputLabel>
          <InputLabel label={t('payment-method-topup.order')}>
            <Input
              value={String(paymentMethodGroup.order)}
              onChange={value =>
                setPaymentMethodGroup({
                  ...paymentMethodGroup,
                  order: parseNumberOrDefault(value, paymentMethodGroup.order),
                })
              }
            />
          </InputLabel>
        </div>
        <div className={styles.child}>
          <InputLabel label={t('payment-method-topup.name-translation-key')}>
            <Input
              value={paymentMethodGroup.nameTranslationKey}
              onChange={value => setPaymentMethodGroup({ ...paymentMethodGroup, nameTranslationKey: value })}
            />
          </InputLabel>
          <InputLabel label={t('payment-method-topup.country-code')}>
            <Input
              value={paymentMethodGroup.countryCode}
              onChange={value => setPaymentMethodGroup({ ...paymentMethodGroup, countryCode: value })}
            />
          </InputLabel>
          <InputLabel label={t('payment-method-topup.description-translation-key')}>
            <Input
              value={paymentMethodGroup.descriptionTranslationKey}
              onChange={value => setPaymentMethodGroup({ ...paymentMethodGroup, descriptionTranslationKey: value })}
            />
          </InputLabel>
          <InputLabel label={t('payment-method-topup.reason')}>
            <Input
              value={paymentMethodGroup.reason}
              onChange={value => setPaymentMethodGroup({ ...paymentMethodGroup, reason: value })}
            />
          </InputLabel>
          <Checkbox
            checked={paymentMethodGroup.isEnabled}
            labelPosition={'left'}
            onChange={(value: boolean) => setPaymentMethodGroup({ ...paymentMethodGroup, isEnabled: value })}
          >
            <div className={styles.checkboxLabel}>{t('payment-method-topup.is-enabled')}</div>
          </Checkbox>
        </div>
      </div>
      <div className={styles.button}>
        <Button
          disabled={!isValid || loading}
          textTransform='uppercase'
          onClick={onSubmit}
        >
          {t('payment-method-topup.save-button')}
        </Button>
      </div>
    </Modal>
  );
}
