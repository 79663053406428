import React from 'react';
import Table from '@/components/common/tables/Table';
import type PaymentMethodGroup from '@/types/PaymentMethodGroup';
import { useTranslation } from 'react-i18next';
import { type Dispatch } from 'react';
import LinkButton from '@/components/common/button/LinkButton';

export default function PaymentMethodsGroupsTableRow({
  paymentMethodsGroup,
  setActiveGroupId,
}: Readonly<{
  paymentMethodsGroup: PaymentMethodGroup;
  setActiveGroupId: Dispatch<string>;
}>): React.JSX.Element {
  const { t } = useTranslation('configurations');

  const handleClick = (): void => {
    setActiveGroupId(paymentMethodsGroup.id || '');
  };

  return (
    <Table.Row>
      <Table.Cell>
        <LinkButton onClick={() => handleClick()}>{paymentMethodsGroup.name}</LinkButton>
      </Table.Cell>
      <Table.Cell>{paymentMethodsGroup.id}</Table.Cell>
      <Table.Cell>{paymentMethodsGroup.order}</Table.Cell>
      <Table.Cell>{paymentMethodsGroup.reason}</Table.Cell>
      <Table.Cell>
        {paymentMethodsGroup.isEnabled ? t('payment-method-topup.yes') : t('payment-method-topup.no')}
      </Table.Cell>
    </Table.Row>
  );
}
