import { useTranslation } from 'react-i18next';
import { type Dispatch, useEffect } from 'react';
import { useAppSelector, type RootState, type AppDispatch } from '@/lib/redux';
import { useDispatch } from 'react-redux';

import styles from '@/pages/configurations/countries-configurations/CountriesConfigurations.module.scss';
import UserPermissionsGuard from '@/components/user-permissions/UserPermissionsGuard';
import Button from '@/components/common/button/Button';
import { Permissions } from '@/types';
import { getPaymentMethodTopupByGroup } from '@/lib/redux/slices/payment-methods-topup/actions';
import { reset } from '@/lib/redux/slices/payment-methods-topup/slice';
import Spinner from '@/components/common/spinners/Spinner';
import BackButton from '@/components/common/button/implementations/BackButton';
import Table from '@/components/common/tables/Table';
import PaymentMethodTableRow from './PaymentMethodsTableRow';
import { green } from '@/utils/colors/green';

export default function PaymentMethodsTable({
  groupId,
  setActiveGroupId,
  setOpen,
}: Readonly<{
  groupId: string;
  setActiveGroupId: Dispatch<string>;
  setOpen: Dispatch<boolean>;
}>): React.JSX.Element {
  const { paymentMethods, successCreateMethod, loadingGetMethods } = useAppSelector(
    (state: RootState) => state.paymentMethodTopup,
  );
  const { country } = useAppSelector((state: RootState) => state.country);
  const { t } = useTranslation('configurations');
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (groupId) {
      void dispatch(getPaymentMethodTopupByGroup({ countryCode: country.countryCode, groupId }));
    }
  }, [dispatch, country.countryCode, groupId]);

  useEffect(() => {
    if (successCreateMethod && country?.countryCode && groupId) {
      void dispatch(getPaymentMethodTopupByGroup({ countryCode: country.countryCode, groupId }));
    }
  }, [dispatch, country.countryCode, groupId, successCreateMethod]);

  const hangleBackButtonClick = (): void => {
    setActiveGroupId('');
    dispatch(reset());
  };

  return (
    <>
      {loadingGetMethods && <Spinner />}
      {!loadingGetMethods && (
        <>
          <div className={styles.title}>
            <div className={styles.backButton}>
              <BackButton
                onClick={hangleBackButtonClick}
                color={green.cssColor}
              >
                {t('payment-method-topup.back-to-payments-groups')}
              </BackButton>
            </div>
            <UserPermissionsGuard permission={Permissions.countryConfigEdit}>
              <div className={styles.buttons}>
                <Button
                  textTransform='uppercase'
                  onClick={() => setOpen(true)}
                >
                  + {t('payment-method-topup.button-new-method')}
                </Button>
              </div>
            </UserPermissionsGuard>
          </div>
          <div className={styles.emptyCard}>
            <Table cols={7}>
              <Table.Head>{t('payment-method-topup.name')}</Table.Head>
              <Table.Head>{t('payment-method-topup.id')}</Table.Head>
              <Table.Head>{t('payment-method-topup.order')}</Table.Head>
              <Table.Head>{t('payment-method-topup.reason')}</Table.Head>
              <Table.Head>{t('payment-method-topup.channel-id')}</Table.Head>
              <Table.Head>{t('payment-method-topup.enabled')}</Table.Head>
              <Table.Head>{t('payment-method-topup.actions')}</Table.Head>
              {paymentMethods.map(paymentMethod => {
                return (
                  <PaymentMethodTableRow
                    key={paymentMethod.id}
                    paymentMethod={paymentMethod}
                  />
                );
              })}
            </Table>
          </div>
        </>
      )}
    </>
  );
}
