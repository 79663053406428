import { useRef } from 'react';
import styled from 'styled-components';
import {
  AcceptedDocs,
  AccountData,
  AddressData,
  BalancesData,
  ContactInfoData,
  DeviceInfo,
  PersonalData,
} from './data';

import LinkButton from '@/components/common/button/LinkButton';
import { type RootState, useAppSelector } from '@/lib/redux';
import { usePermissions } from '@/utils/hooks/usePermissions';
import { Permissions } from '@/types';
import EditIcon from '@/components/icons/EditIcon';
import CrossIcon from '@/components/icons/CrossIcon';
import { marine, white } from '@/utils/colors';
import styles from './CustomerPii.module.scss';
import { useTranslation } from 'react-i18next';

export const StyledHeader = styled.div`
  color: ${white.cssColor};
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.5rem;
  z-index: 10;
`;

export interface CustomerPiiProps {
  editing: boolean;
  onEdit: () => void;
  onCancel: () => void;
}

export default function CustomerPii({
  editing,
  onEdit,
  onCancel,
}: Readonly<CustomerPiiProps>): React.JSX.Element | null {
  const customer = useAppSelector((state: RootState) => state.customer.customer);
  const isEditable = usePermissions(Permissions.customerPIEdit);
  const overlayRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation('customerOverview');

  if (customer === null || customer === undefined) {
    return null;
  }

  return (
    <div
      ref={overlayRef}
      className={styles['customer-pii']}
    >
      <div className={styles['customer-pii__header']}>
        <StyledHeader>{t('widgets.customer-pii.header-title')}</StyledHeader>
        {isEditable && !editing && (
          <LinkButton onClick={onEdit}>
            <EditIcon />
          </LinkButton>
        )}
        {editing && (
          <LinkButton onClick={onCancel}>
            <CrossIcon
              width={`${15 / 16}rem`}
              height={`${14 / 16}rem`}
              color={marine.cssColor}
            />
          </LinkButton>
        )}
      </div>
      <div className={styles['customer-pii__line']} />
      <div className={styles['customer-pii__data']}>
        <PersonalData
          firstName={customer.firstName}
          lastName={customer.lastName}
          dateOfBirth={customer.birthDate}
          fiscalNumber={customer.fiscalNumber}
          fiscalNumberType={customer.fiscalNumberType}
        />
        <AccountData
          customerId={customer.customerId}
          customerStatus={customer.status}
          signUpDateOtp={customer.signUpTime}
          signUpDateAccount={customer.accountCreationTime}
          riskRating={customer.amlRating}
        />
        <AddressData
          addressLine1={customer.addressLine1}
          addressLine2={customer.addressLine2}
          city={customer.addressCity}
          state={customer.addressState}
          country={customer.addressCountryCode}
          postalCode={customer.addressPostalCode}
        />
        <ContactInfoData
          phoneNumber={customer.phoneNumber}
          email={customer.email}
        />
        <BalancesData customerId={customer.customerId} />
        <AcceptedDocs
          acceptedDocs={customer.acceptedDocs ?? []}
          customerId={customer.customerId ?? ''}
        />
        <DeviceInfo customerPhoneNumber={customer.phoneNumber} />
      </div>
    </div>
  );
}
