import styles from './ComplianceDocs.module.scss';
import { useAppSelector, type RootState, useAppDispatch } from '@/lib/redux';
import Typography from '@/components/common/typography/Typography';
import { mediumPurple } from '@/utils/colors';
import { useTranslation } from 'react-i18next';
import InputLabel from '@/components/common/labels/InputLabel';
import Input from '@/components/common/inputs/Input';
import styled from 'styled-components';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Accordion from '@/components/common/accordion/Accordion';
import { AccordionTitle } from '@/components/configurations';
import { successToast } from '@/utils/toastMessage';
import {
  resetComplianceDocsPolicy,
  resetComplianceDocsTermsAndCondition,
} from '@/lib/redux/slices/compliance-docs/slice';
import { objectDeepEquality } from '@/utils/helperFunctions';
import { addPrivacyPolicy, addTermsAndConditions } from '@/lib/redux/slices/compliance-docs/actions';
import { resetSucces } from '@/lib/redux/slices/country/slice';
import Button from '@/components/common/button/Button';

const GridItem = styled.div<{ $cell?: [number?, number?]; $align?: string }>`
  grid-column: ${({ $cell = [1, 1] }) => {
    const start = $cell[0];
    const span = $cell[1] ?? 1;
    return `${start} / span ${span}`;
  }};
  display: flex;
  flex-direction: row;
  justify-content: ${({ $align }) => $align ?? 'center'};
  align-content: center;
  @media (max-width: 1090px) {
    grid-column: 1 / span 5;
  }
`;

const ComplianceDocs = (): JSX.Element => {
  const { t } = useTranslation('configurations');
  const {
    policy: compliancePolicy,
    termsAndCondition: complianceTermsAndCondition,
    successfulPolicy,
    successfulTermsAndCondition,
  } = useAppSelector((state: RootState) => state.complianceDocs);
  const [active, setActive] = useState<boolean>(false);
  const { country, isLoading } = useAppSelector((state: RootState) => state.country);
  const [policyParameters, setPolicyParameters] = useState({
    ...compliancePolicy,
    countryCode: country.countryCode,
  });

  const [termsAndConditionParameters, setTermsAndConditionParameters] = useState({
    ...complianceTermsAndCondition,
    countryCode: country.countryCode,
  });

  const isPolicyExisting = compliancePolicy.countryCode.length > 0;
  const isTermsAndConditionExisting = complianceTermsAndCondition.countryCode.length > 0;

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (successfulTermsAndCondition) {
      successToast();
      dispatch(resetComplianceDocsTermsAndCondition());
    }
  }, [successfulTermsAndCondition, dispatch]);

  useEffect(() => {
    if (successfulPolicy) {
      successToast();
      dispatch(resetComplianceDocsPolicy());
    }
  }, [successfulPolicy, dispatch]);

  useEffect(() => {
    if (country.countryCode.length > 0 && policyParameters.countryCode.length === 0) {
      setPolicyParameters({ ...policyParameters, countryCode: country.countryCode });
    }
  }, [policyParameters, country]);

  useEffect(() => {
    if (country.countryCode.length > 0 && termsAndConditionParameters.countryCode.length === 0) {
      setTermsAndConditionParameters({ ...termsAndConditionParameters, countryCode: country.countryCode });
    }
  }, [termsAndConditionParameters, country]);

  const { allDocsFieldsValid } = useMemo(() => {
    const {
      complianceDocVersion: policyVersion,
      countryCode: policyCountryCode,
      complianceDocUrl: policyUrl,
    } = policyParameters;

    const {
      complianceDocVersion: termsVersion,
      countryCode: termsCountryCode,
      complianceDocUrl: termsUrl,
    } = termsAndConditionParameters;
    // List of all fields to check
    const fields = [policyVersion, policyCountryCode, policyUrl, termsVersion, termsCountryCode, termsUrl];
    // Count the number of filled fields
    const filledFieldsCount = fields.filter(field => field.length > 0).length;
    // Check if all fields are filled
    const allDocsFieldsValid = filledFieldsCount === fields.length;
    // Return an object with both values
    return {
      allDocsFieldsValid,
    };
  }, [policyParameters, termsAndConditionParameters]);

  const isComplianceDocsChanged =
    !objectDeepEquality(policyParameters, compliancePolicy) ||
    !objectDeepEquality(termsAndConditionParameters, complianceTermsAndCondition);

  const handleComplianceDocsSubmit = useCallback((): void => {
    if (!objectDeepEquality(termsAndConditionParameters, complianceTermsAndCondition)) {
      void dispatch(addTermsAndConditions({ body: { ...termsAndConditionParameters } }));
    }
    if (!objectDeepEquality(policyParameters, compliancePolicy)) {
      void dispatch(addPrivacyPolicy({ body: { ...policyParameters } }));
    }
    if (isPolicyExisting) {
      void dispatch(resetSucces());
    }
  }, [
    dispatch,
    termsAndConditionParameters,
    policyParameters,
    complianceTermsAndCondition,
    compliancePolicy,
    isPolicyExisting,
  ]);

  const handleSubmit = (): void => {
    if (allDocsFieldsValid) {
      handleComplianceDocsSubmit();
    }
  };

  const checkSubmitDisabled = useMemo((): boolean => {
    if (isLoading) return true;

    if (isPolicyExisting) {
      return !isComplianceDocsChanged || !allDocsFieldsValid;
    }

    return !allDocsFieldsValid;
  }, [isLoading, isPolicyExisting, isComplianceDocsChanged, allDocsFieldsValid]);

  const renderContent = (
    <div className={styles.card}>
      <div className={styles.cardContent}>
        <div className={styles.label}>
          <Typography
            variant='p3'
            color={mediumPurple.cssColor}
          >
            {t('new-country.compliance-docs-description')}
          </Typography>
        </div>
        <div className={styles.inputCard}>
          <div className={styles.gridCard}>
            <GridItem
              $align='start'
              $cell={[1]}
            >
              <Typography variant='p3Bold'>{t('new-country.terms-&-conditions')}</Typography>
            </GridItem>
            <GridItem $cell={[1, 2]}>
              <InputLabel label={t('new-country.input-label-docs.version')}>
                <Input
                  focused={
                    isTermsAndConditionExisting &&
                    complianceTermsAndCondition.complianceDocVersion !==
                      termsAndConditionParameters.complianceDocVersion
                  }
                  onChange={(value: string) =>
                    setTermsAndConditionParameters({ ...termsAndConditionParameters, complianceDocVersion: value })
                  }
                  value={termsAndConditionParameters.complianceDocVersion}
                />
              </InputLabel>
            </GridItem>
            <GridItem $cell={[3, 2]}>
              <InputLabel label={t('new-country.input-label-docs.country-code')}>
                <Input
                  disabled={true}
                  value={country.countryCode}
                />
              </InputLabel>
            </GridItem>
            <GridItem $cell={[5, 2]}>
              <InputLabel label={t('new-country.input-label-docs.url')}>
                <Input
                  focused={
                    isTermsAndConditionExisting &&
                    complianceTermsAndCondition.complianceDocUrl !== termsAndConditionParameters.complianceDocUrl
                  }
                  onChange={(value: string) =>
                    setTermsAndConditionParameters({ ...termsAndConditionParameters, complianceDocUrl: value })
                  }
                  value={termsAndConditionParameters.complianceDocUrl}
                />
              </InputLabel>
            </GridItem>
            <GridItem
              $align='start'
              $cell={[1]}
            >
              <Typography variant='p3Bold'>{t('new-country.privacy-policy')}</Typography>
            </GridItem>
            <GridItem $cell={[1, 2]}>
              <InputLabel label={t('new-country.input-label-docs.version')}>
                <Input
                  focused={
                    isPolicyExisting && compliancePolicy.complianceDocVersion !== policyParameters.complianceDocVersion
                  }
                  onChange={(value: string) =>
                    setPolicyParameters({ ...policyParameters, complianceDocVersion: value })
                  }
                  value={policyParameters.complianceDocVersion}
                />
              </InputLabel>
            </GridItem>
            <GridItem $cell={[3, 2]}>
              <InputLabel label={t('new-country.input-label-docs.country-code')}>
                <Input
                  disabled={true}
                  value={country.countryCode}
                />
              </InputLabel>
            </GridItem>
            <GridItem $cell={[5, 2]}>
              <InputLabel label={t('new-country.input-label-docs.url')}>
                <Input
                  focused={isPolicyExisting && compliancePolicy.complianceDocUrl !== policyParameters.complianceDocUrl}
                  onChange={(value: string) => setPolicyParameters({ ...policyParameters, complianceDocUrl: value })}
                  value={policyParameters.complianceDocUrl}
                />
              </InputLabel>
            </GridItem>
          </div>
        </div>
      </div>
      <div className={styles.buttons}>
        <Button
          textTransform='uppercase'
          disabled={checkSubmitDisabled}
          onClick={handleSubmit}
        >
          {t('new-country.button-submit-configuration')}
        </Button>
      </div>
    </div>
  );

  return (
    <Accordion
      title={
        <AccordionTitle
          isValid={isPolicyExisting && !isComplianceDocsChanged}
          title='Compliance Docs'
        />
      }
      content={renderContent}
      isActive={active}
      onClick={() => setActive(!active)}
    />
  );
};

export default ComplianceDocs;
