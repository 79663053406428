import { type Fee, type Transaction, TransactionType } from '@/types';

export function getTransactionTypeLabel(trx: Transaction, customerId?: string): string {
  switch (trx.transactionType) {
    case TransactionType.Card:
      return 'Cards';
    case TransactionType.Transfer:
      if (customerId && customerId === trx.destination?.customerId) {
        return 'TransferIn';
      }
      return 'TransferOut';
    default:
      return trx.transactionType ?? '';
  }
}

interface AmountOptions {
  customerId?: string;
  applyFees?: boolean;
}

export function getTransactionAmount(trx: Transaction, { customerId, applyFees }: AmountOptions): number {
  let amount: number = trx.requestedAmount ?? 0;
  if (customerId) {
    if (trx.source?.customerId === customerId) {
      amount = trx.source.balanceAmount ?? amount;
      if (applyFees) {
        amount += getTransactionFees(trx.source);
      }
    } else if (trx.destination?.customerId === customerId) {
      amount = trx.destination.balanceAmount ?? amount;
    }
  }
  return amount;
}

export function getTransactionAmountLabel(trx: Transaction, options: AmountOptions): string {
  let currency: string = trx.requestedAmountCurrency ?? '';
  const { customerId } = options;
  if (customerId) {
    if (trx.source?.customerId === customerId) {
      currency = trx.source.balanceAmountCurrency ?? currency;
    } else if (trx.destination?.customerId === customerId) {
      currency = trx.destination.balanceAmountCurrency ?? currency;
    }
  }
  return `${currency} ${getTransactionAmount(trx, options)}`;
}

export function getTransactionFeesLabel(trx: Transaction, customerId?: string): string | undefined {
  let source: {
    balanceAmountCurrency?: string;
    percentFee?: Fee;
    percentFxFee?: Fee;
    fixFee?: Fee;
    fixFxFee?: Fee;
  } = trx.source ?? {};
  if (customerId) {
    if (trx.source?.customerId !== customerId && trx.destination?.customerId === customerId) {
      source = trx.destination;
    }
  }
  if (source?.balanceAmountCurrency) {
    return `${source.balanceAmountCurrency} ${getTransactionFees(source)}`;
  }
  return undefined;
}

export function getTransactionFees({
  percentFee,
  percentFxFee,
  fixFee,
  fixFxFee,
}: {
  percentFee?: Fee;
  percentFxFee?: Fee;
  fixFee?: Fee;
  fixFxFee?: Fee;
}): number {
  const fees: number[] = [
    percentFee?.amount ?? 0,
    percentFxFee?.amount ?? 0,
    fixFee?.amount ?? 0,
    fixFxFee?.amount ?? 0,
  ];
  return fees.reduce((a, b) => a + b, 0);
}
