import FiltersModal from '@/components/common/filters/FiltersModal';
import InputLabel from '@/components/common/labels/InputLabel';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useDateRangeFilter,
  usePspTransactionIdFilter,
  useSlipIdFilter,
  useTransactionIdFilter,
  useTransactionStatusFilter,
  useTransactionTypeFilter,
} from './filterHooks';
import DebounceInput from '@/components/common/inputs/DebounceInput';
import { type Option } from '@/utils/interfaces';
import { TransactionStatus, TransactionType } from '@/types';
import styles from './TransactionHistoryFilters.module.scss';
import DateRangeInput from '@/components/common/inputs/DateRangeInput';
import MultiSelectDropdown from '@/components/common/filters/MultiSelectDropdown';
import TransactionHistoryFiltersHeader from '@/components/customer/transaction-history/filters/TransactionHistoryFiltersHeader';

const ALL_TRANSACTION_TYPES: Option[] = Object.values(TransactionType).map(type => ({
  text: type === TransactionType.Card ? 'Cards' : type,
}));
const ALL_TRANSACTION_STATUSES: Option[] = Object.values(TransactionStatus).map(type => ({
  text: type,
}));

export default function TransactionHistoryFilters(): JSX.Element {
  const { t } = useTranslation(['customerOverview', 'global']);
  const [showModal, setShowModal] = useState(false);
  const [transactionIdFilter, setTransactionIdFilter] = useTransactionIdFilter();
  const [pspTransactionIdFilter, setPspTransactionIdFilter] = usePspTransactionIdFilter();
  const [slipIdFilter, setSlipIdFilter] = useSlipIdFilter();
  const [transactionTypes, setTransactionTypes] = useTransactionTypeFilter();
  const [transactionStatuses, setTransactionStatuses] = useTransactionStatusFilter();
  const [dates, setDates] = useDateRangeFilter();
  const clearAllFilters = (): void => {
    setTransactionIdFilter('');
    setSlipIdFilter('');
    setPspTransactionIdFilter('');
    setTransactionTypes([]);
    setTransactionStatuses([]);
    setDates(['', '']);
  };
  const selectedTransactionTypeOptions = useMemo(
    () =>
      transactionTypes.map(type => ({
        text: type,
      })),
    [transactionTypes],
  );

  const selectedTransactionStatusOptions = useMemo(
    () =>
      transactionStatuses.map(type => ({
        text: type,
      })),
    [transactionStatuses],
  );

  const disabledClearAll = useMemo(
    () =>
      !transactionIdFilter &&
      !slipIdFilter &&
      transactionTypes.length === 0 &&
      transactionStatuses.length === 0 &&
      !dates[0] &&
      !dates[1] &&
      !pspTransactionIdFilter,
    [transactionIdFilter, slipIdFilter, transactionStatuses, transactionTypes, dates, pspTransactionIdFilter],
  );

  return (
    <>
      <TransactionHistoryFiltersHeader
        disabledClearAll={disabledClearAll}
        onClick={() => setShowModal(true)}
        label={t('filters.button-label', { ns: 'global' })}
      />
      {showModal && (
        <FiltersModal
          onClose={() => setShowModal(false)}
          clearAllFilters={clearAllFilters}
          disabledClearAll={disabledClearAll}
        >
          <InputLabel label={t('widgets.transaction-history.filters.transaction-type-label')}>
            <MultiSelectDropdown
              options={ALL_TRANSACTION_TYPES}
              selectedOptions={selectedTransactionTypeOptions}
              onChange={(options: Option[]) => setTransactionTypes(options.map(x => x.text))}
            />
          </InputLabel>
          <InputLabel label={t('widgets.transaction-history.filters.time-range-label')}>
            <DateRangeInput
              value={dates}
              setValue={setDates}
            >
              <div className={styles.dateRange}>
                <DateRangeInput.From />
                <DateRangeInput.To />
              </div>
            </DateRangeInput>
          </InputLabel>
          <InputLabel label={t('widgets.transaction-history.filters.transaction-status-label')}>
            <MultiSelectDropdown
              options={ALL_TRANSACTION_STATUSES}
              selectedOptions={selectedTransactionStatusOptions}
              onChange={(options: Option[]) => setTransactionStatuses(options.map(x => x.text))}
            />
          </InputLabel>
          <InputLabel label={t('widgets.transaction-history.filters.transaction-id-label')}>
            <DebounceInput
              value={transactionIdFilter ?? ''}
              onChange={setTransactionIdFilter}
            />
          </InputLabel>
          <InputLabel label={t('widgets.transaction-history.filters.slip-id-label')}>
            <DebounceInput
              value={slipIdFilter ?? ''}
              onChange={setSlipIdFilter}
            />
          </InputLabel>
          <InputLabel label={t('widgets.transaction-history.filters.psp-transaction-id-label')}>
            <DebounceInput
              value={pspTransactionIdFilter ?? ''}
              onChange={setPspTransactionIdFilter}
            />
          </InputLabel>
        </FiltersModal>
      )}
    </>
  );
}
