import { type PayloadAction, createSlice } from '@reduxjs/toolkit';
import { getTrustedDevice } from './actions';
import { type TrustedDevice } from '@/types/TrustedDevice';

export interface TrustedDeviceSliceState {
  trustedDevice?: TrustedDevice[];
  loading: boolean;
}

const initialState: TrustedDeviceSliceState = {
  trustedDevice: [],
  loading: true,
};

export const trustedDeviceSlice = createSlice({
  name: 'trustedDevice',
  initialState,
  reducers: {
    reset() {
      return { ...initialState };
    },
  },
  extraReducers: builder => {
    builder.addCase(getTrustedDevice.fulfilled, (state, action) => {
      state.trustedDevice = [...action.payload];
      state.loading = false;
    });
    builder.addCase(getTrustedDevice.rejected, (state, action: PayloadAction<any>) => {
      if (action.payload.response.data.errors[0].errorMessage === 'err_no_certificates_found') {
        state.trustedDevice = [];
        state.loading = false;
      }
    });
  },
});

export const { reset } = trustedDeviceSlice.actions;
